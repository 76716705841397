import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { SEO } from '../components'
import ContentLayout from "../components/layouts/content"
import HTMLContent from "../components/general/html-content";
import "../assets/styles/components/content-page.scss";


export const ContentPageTemplate = props => {
  const { page } = props;
  const data = page.frontmatter;

  const pageData = {
    title: data.title,
    description: data.subtitle
  }

  return (
    <main >
      <title>{data.title}</title>
      <ContentLayout page={pageData} classes="content-page">
        {/* The page.html is actually markdown when viewing the page from the netlify CMS,
              so we must use the ReactMarkdown component to parse the markdown in that case  */}
          {page.bodyIsMarkdown ? (
            <ReactMarkdown source={page.html} />
          ) : (
            <HTMLContent content={page.html} />
          )}
      </ContentLayout>
    </main>
  );
};

const ContentPage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const pathName = isBrowser ? window.location.pathname: ""
  const { markdownRemark: page } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription },
    },
  } = page;

  return (
    <>
      <SEO
        title={seoTitle}
        desc={seoDescription}
        pathname={pathName}
      />
      <ContentPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </>
  );
};

ContentPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContentPage;

export const contentPageQuery = graphql`
  query ContentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        seo {
          title
          description
        }
      }
    }
  }
`;